import React from 'react'
import DonateInfo from '../Components/Fundraising/DonateInfo'

const Donate = () => {
  return (
    <div>
        <DonateInfo />
    </div>
  )
}

export default Donate