import React from 'react'
import {Link} from "react-router-dom";

const FundCont = () => {

  return (
    <div className=''>
        <div id="fundraisingHero" className='max-w-[1240px] w-full mt-[-5%] md:mb-10 md:mt-auto h-[86.5vh] mx-auto text-center'>
            <h1 className='mx-6 text-3xl font-medium pt-16 md:pt-9 lg:pt-32 md:text-4xl'>Donations, Fundraising, Events, and Volunteering</h1>
            <h2 className='hidden md:block text-2xl font-semibold mt-16 md:text-3xl md:mt-16'>Get Involved, Make a Difference!</h2>
            <p className='hidden md:block mt-12 md:mt-16 text-[1.125rem] md:w-[80%] mx-auto'>The cornerstones of our organization are the people who make up our team. Volunteers from all walks of life, with their own unique skill sets and passions. Working together for one goal to make a difference in our community by opening up oppurtunities. We seek out training with experts who can mentor and guide projects. If you would like to get involvd or volunteer with us please get in touch!</p>
         </div>
         <h2 className='md:hidden text-2xl font-semibold mt-[4%] text-center'>Get Involved, Make a Difference!</h2>
         <p className='md:hidden text-[1.125rem] w-[92%] mb-[9%] text-center mx-auto'>The cornerstones of our organization are the people who make up our team. Volunteers from all walks of life, with their own unique skill sets and passions. Working together for one goal to make a difference in our community by opening up oppurtunities. We seek out training with experts who can mentor and guide projects. If you would like to get involvd or volunteer with us please get in touch!</p>
         <div className='max-w-[1240px] mx-auto px-16 grid gap-6 lg:grid-cols-3 lg:px-0'>
            <div id="fundraising" className='h-[60vh] lg:h-[80vh] rounded-lg bg-left flex'>
                <Link onClick={() => {window.scrollTo({top: 0, behavior: "smooth"})}}className='m-auto' to="/donate">
                    <button className='text-4xl hover:scale-105 shadow-xl bg-white w-[200px] rounded-md font-medium mt-6 py-3 text-black border-black border-2 hover:bg-black hover:text-white hover:border-white duration-150'>Donate</button>
                </Link>
            </div> 
            <div id="volunteer" className='h-[60vh] lg:h-[80vh] rounded-lg flex'>
                <Link onClick={() => {window.scrollTo({top: 0, behavior: "smooth"})}}className='m-auto' to="/volunteer">
                    <button className='text-4xl hover:scale-105 shadow-xl bg-white w-[200px] rounded-md font-medium mt-6 mx-auto py-3 text-black border-black border-2 hover:bg-black hover:text-white hover:border-white duration-150'>Volunteer</button>
                </Link>
            </div>
            <div id="event" className='h-[60vh] lg:h-[80vh] rounded-lg flex'>
            <Link onClick={() => {window.scrollTo({top: 0, behavior: "smooth"})}}className='m-auto' to="/events">
                <button className='text-4xl hover:scale-105 shadow-xl bg-white w-[200px] rounded-md font-medium mt-6 mx-auto py-3 text-black border-black border-2 hover:bg-black hover:text-white hover:border-white duration-150'>Events</button>
            </Link>
            </div>
         </div>
    </div>
  )
}

export default FundCont