import React from 'react'
import DonateHero from '../Images/DonateHero.jpg'
import Footer from '../Footer'

const Donate = () => {
  return (
    <div className=''>
        <div className='md:hidden text-center'>
            <div className='h-[86.25vh] flex flex-col justify-center items-center gap-4'>
                <h1 className='text-2xl font-medium w-[90%]'>Would You like to Donate to Make a Difference?</h1>
                <img src={DonateHero} alt="" className='w-[87.5%]'/>
                <h5 className='text-[1.125rem] w-[90%]'>In our fundraising, we have several different campaigns. It is easy to find something that personally inspires you.</h5>
            </div>
            <p className='font-medium border-b-2 b-bg-gray-900 w-[87%] mx-auto text-[1.5rem] text-left'>We can recieve Donations via:</p>
            <div className='my-4 grid grid-cols-3 text-right w-[87%] mx-auto gap-4'>
                <p className='text-left'>Our Bank:</p>
                <p className='col-span-2'>African Banking Corporation of Zimbabwe Limited</p>
                <p className='text-left'>Address: </p>
                <p className='col-span-2'>Hwange Branch 32 Coronation Drive Hwange</p>
                <p className='text-left'>Account Number:</p>
                <p className='col-span-2'>47868076633189</p>
                <p className='text-left'>Swift Code:</p>
                <p className='col-span-2'>FMBZZWHX</p>
            </div>
                <p className='font-medium border-b-2 w-[87%] b-bg-gray-900 mx-auto text-[1.5rem] text-left'>We can recieve remittances via digital banking plataforms: World Remit, Western Union, or Money Gram</p>
                <div className='my-4 grid grid-cols-3 text-right w-[87%] mx-auto gap-4'>
                    <p className='text-left'>Please address:</p>
                    <p className='col-span-2'>Ndlelende Ncube</p>
                    <p className='text-left'>Location:</p>
                    <p className='col-span-2'>Hwange</p>
                    <p className='text-left'>Telephone:</p>
                    <p className='col-span-2'>+263783297571</p>
                </div>
                <p className='w-[87%] mx-auto border-t pt-4 b-bg-gray-900'>For further information or if you would like to donate goods and services please contact us at info@tikobanezimbabwe.org to make arrangements!</p>                 
            </div>

            <div id="donateHero" className='hidden md:block max-w-[1240px] w-full md:mt-auto h-[86.5vh] mx-auto text-center'>
                <div className=' mx-auto' id="donate">
                    <h4 className='text-3xl font-semibold pt-6'>Would You like to Donate to Make a Difference?</h4>
                <h5 className='text-2xl mt-2 w-[650px] mx-auto text-left'>In our fundraising, we have several different campaigns. It is easy to find something that personally inspires you.</h5>
                <p className='text-2xl my-2 w-[650px] mx-auto text-left font-medium'>We can recieve Donations via:</p>
                <div className='max-w-[655px] my-4 grid grid-cols-3 text-right w-[87%] mx-auto gap-4 border-y-2 border-black'>
                    <p className='text-left'>Our Bank:</p>
                    <p className='col-span-2'>African Banking Corporation of Zimbabwe Limited</p>
                    <p className='text-left'>Address: </p>
                    <p className='col-span-2'>Hwange Branch 32 Coronation Drive Hwange</p>
                    <p className='text-left'>Account Number:</p>
                    <p className='col-span-2'>47868076633189</p>
                    <p className='text-left'>Swift Code:</p>
                    <p className='col-span-2'>FMBZZWHX</p>
                </div>
                <p className='text-2xl my-2 w-[650px] mx-auto text-left font-medium'>We can also recieve remittances via digital banking plataforms via World Remit, Western Union, or Money Gram</p>
                <div className='my-4 max-w-[655px] grid grid-cols-3 text-right w-[87%] mx-auto gap-4 border-y-2 border-black'>
                    <p className='text-left'>Please address:</p>
                    <p className='col-span-2'>Ndlelende Ncube</p>
                    <p className='text-left'>Location:</p>
                    <p className='col-span-2'>Hwange</p>
                    <p className='text-left'>Telephone:</p>
                    <p className='col-span-2'>+263783297571</p>
                </div>
                <p className='max-w-[655px] mx-auto'>For further information or if you would like to donate goods and services please contact us at info@tikobanezimbabwe.org to make arrangements!</p>                 
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Donate