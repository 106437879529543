import React from 'react'
import workAnimal from '../Images/work-animal.jpg'
import domesticAnimal from '../Images/domestic-animal.jpg'
import domesticAnimal2 from '../Images/domestic-animal2.jpg'
import farmAnimal from '../Images/farm-animal.jpg'

const AnimalWelfare = () => {
  return (
    <div className='col-span-full mt-6'>
        <div className='md:hidden'>
            <ul id="welfareContainer" className='grid grid-cols-2 w-full text-left'>
                <img src={workAnimal} alt="/" className="w-[75%] justify-self-end col-start-2 row-start-1 row-end-4"/>
                <li className='col-start-1 row-start-1 row-end-2  py-1.5 font-medium border-b border-gray-400 w-full rounded-t-lg text-2xl'>Working Animals </li>
                <li className='col-start-1 row-start-2 row-end-3  py-1.5 border-b border-gray-200 w-full'>Outreach Clinics & Husbandry Education</li>
                <li className='col-start-1 row-start-3 row-end-4  py-1.] border-b border-gray-200 w-full'>Safe Harnessing & Rocket Stove Projects</li>
            </ul>
            <ul id="welfareContainer" className='grid grid-cols-2 w-full text-left mt-8'>
                <img src={domesticAnimal} alt="/" className="col-start-1 w-[75%] justify-self-start row-start-1 row-end-4"/>
                <li className='col-start-2 text-right py-1.5 font-medium border-b border-gray-500 text-2xl'>Domestic Pets </li>
                <li className='col-start-2 text-right py-1.5  border-b border-gray-200'>Education & Clinical Outreach</li>
                <li className='col-start-2 text-right  py-1.5  border-b border-gray-200 w-full rounded-t-lg'>Managing Street Dogs & Cats</li>
                <img src={domesticAnimal2} alt="/" className="w-[75%] mt-3 justify-self-start row-start-4 row-end-6"/>
                <li className='col-start-2 text-right  py-1.5  border-b border-gray-200'>See our Veterinary Professionals Outreach that keeps our community's animals healthy</li>
                <li className='col-start-2 text-right  py-1.5 border-b border-gray-200'>Our Rescues are about what happens next</li>
            </ul>
            <ul id="welfareContainer" className='grid grid-cols-2 w-full text-left mt-8'>
            <img src={farmAnimal} alt="/" className="w-[75%] justify-self-end row-start-1 row-end-4 col-start-2"/>
                <li className='col-start-1 py-1.5 font-medium border-b border-gray-500 w-full text-2xl'>Farmed Animals </li>
                <li className='col-start-1  py-1.5 border-b border-gray-200 w-full'>Advocate for better conditions especially for chickens and pigs</li>
                <li className='col-start-1  py-1.5 border-b border-gray-200 w-full'>Promote a plant based diet for the good of our planet</li>
            </ul>
        </div>




        
        <div className='hidden md:grid grid-cols-3 gap-5 text-[1.15rem]'>
            <ul id="welfareContainer" className='text-left flex flex-col'>
                <li className='py-1.5 font-medium border-b border-gray-400 w-full rounded-t-lg text-[1.82rem]'>Working Animals </li>
                <img src={workAnimal} alt="/" className="mt-4 lg:w-[70%]"/>
                <li className='py-1.5 border-b border-gray-200 w-full'>Outreach Clinics & Husbandry Education</li>
                <li className='py-1.5 border-b border-gray-200 w-full'>Safe Harnessing & Rocket Stove Projects</li>
            </ul>
            <ul id="welfareContainer" className='text-center flex flex-col'>
                <li className='py-1.5 font-medium border-b border-gray-500 text-[1.82rem]'>Domestic Pets </li>
                <img src={domesticAnimal} alt="/" className="mt-4 lg:w-[70%] self-center"/>
                <li className='py-1.5  border-b border-gray-200'>Education & Clinical Outreach</li>
                <li className='py-1.5  border-b border-gray-200 w-full rounded-t-lg'>Managing Street Dogs & Cats</li>
                <img src={domesticAnimal2} alt="/" className="hidden"/>
                <li className='py-1.5  border-b border-gray-200'>See our Veterinary Professionals Outreach that keeps our community's animals healthy</li>
                <li className='py-1.5 border-b border-gray-200'>Our Rescues are about what happens next</li>
            </ul>
            <ul id="welfareContainer" className='text-right flex flex-col'>
                <li className='py-1.5 font-medium border-b border-gray-500 w-full text-[1.82rem]'>Farmed Animals </li>
                <img src={farmAnimal} alt="/" className="mt-4 lg:w-[70%] self-end"/>
                <li className='py-1.5 border-b border-gray-200 w-full'>Advocate for better conditions</li>
                <li className='py-1.5 border-b border-gray-200 w-full'>Promote a plant based diet for our planet</li>
            </ul>
        </div>
    </div>
  )
}

export default AnimalWelfare