//rafce
import React, {useState} from 'react'
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'
import {TiSocialLinkedin, TiSocialFacebook, TiSocialTwitter} from 'react-icons/ti'
import {Link} from 'react-router-dom'
import logo from './Images/Logo.png'

const Navbar = () => {
    const [nav, setNav] = useState(false)

    const handleNav = () => {
        setNav(!nav)
    }

  return (
    //Nav Wrapper
        <div className='flex justify-around items-center max-w-[1240px] mx-auto px-5 h-32'>
            <img className='w-[105px]' src={logo} alt=""/>
            <div className='w-full text-2xl font-bold tracking-wide text-green-600 ml-1'>
                <h1 className='text-[1.4rem] leading-none'>Tikobane Trust Reg</h1>
                <h1 className='text-[1.4rem] leading-none'>No: 239/2018</h1>
            </div>
            <ul className='hidden md:flex items-center'>
                <li className='p-4'><Link to="/">Home</Link></li>
                <li className='p-4'><Link to="/about">About</Link></li>
                <li className='p-4'><Link to="/fundraising">Fundraising</Link></li>
                <li className='p-4'><Link to="/contact">Contact</Link></li>
                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Tikobane/"><TiSocialFacebook size={(30)}/></a>
                <a target="_blank" rel="noreferrer" href="https://twitter.com/tikobane"><TiSocialTwitter size={(30)}/></a>
                <a target="_blank" rel="noreferrer" href="#"><TiSocialLinkedin size={(30)}/></a>
            </ul>

            <div onClick={handleNav} className='block md:hidden'>
                {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} /> }
            </div>
            <div className = {nav ? 'fixed left-0 top-0 w-[80%] h-full border-r border-r-gray-900 bg-[#000300] text-white ease-in-out duration-500' : 'fixed left-[-100%]'}>
            <h1 className='w-full text-3xl font-bold m-4'>Tikobane</h1>
                <ul className='p-4 uppercase'>
                    <li className='p-4'><Link onClick={handleNav} to="/">Home</Link></li>
                    <li className='p-4'><Link onClick={handleNav} to="/about">About</Link></li>
                    <li className='p-4'><Link onClick={handleNav} to="/fundraising">Fundraising</Link></li>
                    <li className='p-4'><Link onClick={handleNav} to="/contact">Contact</Link></li>
                    <div className='flex mt-4'>
                        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Tikobane/"><TiSocialFacebook size={(35)}/></a>
                        <a target="_blank" rel="noreferrer" href="https://twitter.com/tikobane"><TiSocialTwitter size={(35)}/></a>
                        <a target="_blank" rel="noreferrer" href="#"><TiSocialLinkedin size={(35)}/></a>
                    </div>
                </ul>
            </div>
        </div>
  )
}

export default Navbar