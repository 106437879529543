import React from 'react'

const Footer = () => {
  return (
<div className='max-w-[1240px] mx-auto py-8 px-4 md:flex justify-between items-center mt-12  border-2 border-black'>
    <h1 className='text-3xl font-bold mb-4'>Tikobane</h1>
    <h6 className='font-medium mb-4'>info@tikobanezimbabwe.org</h6>
    <h6 className='font-medium mb-4'>+263783297571</h6>
    <h6 className='font-medium'>253 Soweto Township Dete</h6>
</div>

  )
}

export default Footer