import React from 'react'
import VolunteerPic from '../Images/Volunteerpic.jpg'

const VolunteerInfo = () => {
  return (
    <div>
        <div className='md:hidden text-center h-[86.25vh] flex flex-col justify-around items-center gap-4 pb-4'>
            <h4 className='text-3xl font-semibold'>Volunteering - Be a Force for Good</h4>
            <img src={VolunteerPic} alt="" className='w-[87.5%]'/>
            <p className='max-w-[90%]'>Our work is never done. We can use all of the help that we can get. One of the ways you can take part is by volunteering. Spread the word about Tikobane Trust and the work we are doing and help us gain the support that we need!</p>
        </div>
      
        <div id="volunteerHero" className='hidden md:block max-w-[1240px] w-full md:mt-auto h-[86.5vh] mx-auto text-center'>
          <div id="vol" className='px-4'>
              <h4 className='text-4xl font-semibold pt-32'>Volunteering</h4>
              <h5 className='mt-[3%] font-medium text-2xl'>Be a Force for Good</h5>
              <p className='mt-[5%] max-w-[550px] mx-auto text-[1.25rem] font-semibold'>Our work is never done. We can use all of the help that we can get. One of the ways you can take part is by volunteering. Spread the word about Tikobane Trust and the work we are doing and help us gain the support that we need!</p>
          </div>
        </div>
    </div>
  )
}

export default VolunteerInfo