import React, {useState, useEffect, useRef} from 'react'
import {AiOutlineVerticalRight, AiOutlineVerticalLeft } from "react-icons/ai";

let count = 0;
let slideInterval;

const Slider = (props) => {
    const images = props.slides;

    const [currentIndex, setCurrentIndex] = useState(0);

    const slideRef = useRef();

    const removeAnimation = () =>  {
        slideRef.current.classList.remove("fade-anim");
    }

    useEffect(() => {
        slideRef.current.addEventListener('animationend', removeAnimation);
        slideRef.current.addEventListener("mouseenter", pauseSlider);
        slideRef.current.addEventListener("mouseleave", startSlider);
        startSlider();
        return () => {
            pauseSlider();
        }
    }, [])

    const startSlider = () => {
        slideInterval = setInterval(() => {
            handleOnNextClick();
        }, 3000);
    }

    const pauseSlider = () => {
        clearInterval(slideInterval);
    }

    const handleOnNextClick = () => {
        count = (count + 1) % images.length;
        setCurrentIndex(count);
        slideRef.current.classList.add('fade-anim');
    }

    const handleOnPrevClick = () => {
        const imagesLength = images.length;
        count = (currentIndex + imagesLength - 1) % imagesLength;
        setCurrentIndex(count);
        slideRef.current.classList.add('fade-anim');
    }

  return (
    <div ref={slideRef} className='w-[82.5%] h-[75vh] select-none relative hidden lg:block mx-auto'>
        <div className='aspect-w-16 aspect-h-9 mx-auto text-center h-[100%]'>
            <img id="slideShow" className='max-h-[100%] w-full mx-auto' src={images[currentIndex]} alt=""/>
        </div>

        <div className='absolute top-1/2 transform -translate-y-3/4 w-full
        px-3 flex justify-between items-center'>
            <button  className="bg-black text-white p-1 rounded-full bg-opacity-50 cursor-pointer hover:bg-opaciy-100 transition" onClick={handleOnPrevClick}><AiOutlineVerticalRight size={30}/></button>
            <button className="bg-black text-white p-1 rounded-full bg-opacity-50 cursor-pointer hover:bg-opaciy-100 transition" onClick={handleOnNextClick}><AiOutlineVerticalLeft size={30} /></button>
        </div>
    </div>
  )
}

export default Slider