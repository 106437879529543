import React from 'react'
import {Link} from "react-router-dom";
import workAnimal from '../Images/work-animal.jpg'
import domesticAnimal from '../Images/domestic-animal.jpg'
import domesticAnimal2 from '../Images/domestic-animal2.jpg'
import AnimalWelfare from './AnimalWelfare';


const Content = () => {
  return (
    <div className='md:text-center grid grid-cols-1 md:grid-cols-2 max-w-[900px] w-[82%] md:w-[90%] mx-auto text-left items-center gap-y-4 lg:max-w-[1240px]'>
        <p className='text-3xl font-bold tracking-wider col-span-full md:mt-14 md:text-4xl md:tracking-wider lg:col-span-1'>Our Mission</p>
        <p className='text-[1.1rem] leading-tight w-[92.5%] md:mx-auto md:col-span-full lg:col-span-1 lg:w-[92.5%]'>We are grassroots educators living in communities adjacent to Hwange National Park, Zimbabwe. Through dialogue guidance and mentorship we open up sustainable oppurtunities and support pragmatic solutions to improve livelihoods.</p>
        <p className='text-3xl font-bold tracking-wider col-span-full md:text-4xl md:tracking-wider md:mt-14 lg:col-span-1 lg:row-start-1'>Our Vision</p>
        <p className='w-[92.5%] text-[1.1rem] leading-tight md:mx-auto md:mt-0 col-span-full lg:col-span-1 lg:w-[92.5%]'>Tikobane is a Nambiya that translates to mean sharing. Through skill sharing, mentorship, and supportive voluntary education projects we work to develope a culture of social responsibility both to each other and the environment around us.</p>
        <p className='text-3xl font-bold col-span-full text-left md:text-center md:text-4xl md:tracking-wider md:mt-14'>Our Focus</p>
        <div className='col-span-full'>
            <ul className='bg-white rounded-lg w-full text-left md:grid md:grid-cols-2'>
                <li className='pr-[7%] py-1.5 md:py-1 border-b w-[89%] border-gray-200 md:mx-auto md:text-center md:pr-0 md:py-5 md:text-[1.25rem] lg:w-[62%]'>Human Wildlife Mitigation</li>
                <li className='pr-[7%] py-1.5 md:py-1 border-b w-[89%] border-gray-200 md:mx-auto md:text-center md:pr-0 md:py-5 md:text-[1.25rem] lg:w-[62%]'>Animal Welfare and Advocacy</li>
                <li className='pr-[7%] py-1.5 md:py-1 border-b w-[89%] border-gray-200 md:mx-auto md:text-center md:pr-0 md:py-5 md:text-[1.25rem] lg:w-[62%]'>Girl Child Mentorship Program</li>
                <li className='pr-[7%] py-1.5 md:py-1 border-b w-[89%] border-gray-200 md:mx-auto md:text-center md:pr-0 md:py-5 md:text-[1.25rem] lg:w-[62%]'>Community Development</li>
                <li className='pr-[7%] py-1.5 md:py-1 border-b w-[89%] border-gray-200 md:mx-auto md:text-center md:pr-0 md:py-5 md:text-[1.25rem] md:w-[45%] lg:w-[32%] md:col-span-full'>Acts of Social Resonsibility</li>
            </ul>
        </div>




        <p className='text-3xl font-bold tracking-wide col-span-full md:text-4xl md:tracking-wider md:mt-14'>Animal Welfare</p>
        <AnimalWelfare />




        <p className='text-3xl font-bold col-span-full md:text-4xl md:tracking-wider md:mt-14'>Animal Village</p>
        <div className='col-span-full'>
            <ul className='bg-white rounded-lg w-full text-left grid md:grid-cols-2 lg:grid-cols-3 md:gap-10 md:mt-7 md:justify-items-center'>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full rounded-t-lg lg:text-left'>Inclusive Community Tourism Venture Eco-Lodge Project</li>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full md:text-right md:pr-0 lg:text-center'>Animal Sancturary Where our Rescues Live</li>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full lg:text-right md:pr-0'>Community Education Center</li>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full rounded-t-lg text-left md:text-right md:pr-0 lg:text-left'>Animal Welfare Clinical support</li>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full text-left lg:text-center md:pr-0'>Grassroots Education on Good Husbandry Practices</li>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full text-left md:text-right md:pr-0'>Community Training Center</li>
            </ul>
        </div>
        <p className='text-3xl font-bold col-span-full md:text-4xl md:tracking-wider md:mt-14 '>Humane Wildlife Conflict</p>
        <div className='col-span-full md:mt-7 '>
            <ul className='bg-white rounded-lg w-full text-left grid md:grid-cols-2 lg:grid-cols-3 md:gap-10 md:justify-items-center'>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full lg:text-left'>Mitigation Training</li>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full rounded-t-lg md:text-right md:pr-0 lg:text-center'>Empowering & equipping field Guardians to manage conflict</li>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full lg:text-right md:pr-0'>Getting the message out that co-existence is possible</li>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full md:text-right md:pr-0 lg:text-left'>Supporting Victims of Wildlife Conflict</li>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full rounded-t-lg text-left lg:text-center md:pr-0'>Our Elephant Fences Project</li> 
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full md:text-right md:pr-0 text-left lg:text-right'>Our Predator Boma Project</li>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full text-left lg:text-left'>Community Mitigation Workshops</li>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full md:text-right md:pr-0 rounded-t-lg lg:text-center'>Getting the message out</li>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full rounded-t-lg lg:text-right'>Follow our Journey with Southern Cross Productions Film Making</li>
                <li className='pr-[7%] w-[89%] md:text-[1.15em] py-1.5 border-b border-gray-200 w-full md:text-right md:pr-0 lg:text-center lg:col-span-full lg:w-[27%]'>Get involved in our theatre storytelling Events</li>
            </ul>
        </div>
        <p className='text-3xl font-bold col-span-full md:text-4xl md:tracking-wider md:mt-16'>Youth Skills Training</p>
        <p className='col-span-full max-w-[700px] w-[92.5%] md:mx-auto'>Life skills: building confidence, literary, and IT mentorships.</p>
        <p className='col-span-full max-w-[700px] w-[92.5%] mt-[-.7em] md:mx-auto'>Providing access to Tools & Training for practical skills: carpentry, metal working workshops.</p>
        <p className='text-3xl font-bold col-span-full md:text-4xl md:tracking-wider md:mt-16'>Social Responsibility</p>
        <p className='col-span-full w-[92.5%] md:mx-auto'>It is our ethos to develop a culture of care for each other and the environment around us. </p>
        <p className='col-span-full w-[92.5%] mt-[-.7em] md:mx-auto'>Keep up with our projects dealing with the impacts of plastic waste and some pragmatic solutions.</p>
        <Link onClick={() => {window.scrollTo({top: 0, behavior: "smooth"})}}className='col-span-full mx-auto' to="/about"><button className='bg-white w-[200px] rounded-md font-medium mt-5 mx-auto py-3 text-black border-black border-2 hover:bg-black hover:text-white hover:border-white col-span-full'>Learn More</button></Link>
    </div>
  )
}

export default Content