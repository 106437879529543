import React from 'react'
import Footer from '../Components/Footer'
import VolunteerInfo from '../Components/Fundraising/VolunteerInfo'

const Volunteer = () => {
  return (
    <div>
        <VolunteerInfo />
        <Footer />
    </div>
  )
}

export default Volunteer