import React, {FormEvent, useState, useRef} from 'react'
import axios from "axios"
import ReCAPTCHA from 'react-google-recaptcha';


type FormState = {
  email: String;
  name: String;
  message: String;
}

type ServiceMessage = {
  class: String;
  text: String;
}

export const ContactForm = () => {

  const formId = 'AaXGLW5K';
  const formSparkUrl = `https://submit-form.com/${formId}`;
  const recaptchaKey = '6Ld_FxMgAAAAAHVm2wPcdxee3x3oMyEajgiOyGO_';
  const recaptchaRef = useRef();

  const initialFormState = {
    email: '',
    name: '',
    message: '',
  };

  const [formState, setFormState] = useState(initialFormState);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState();
  const [recaptchaToken, setRecaptchaToken] = useState();

  const submitForm = async (event: FormEvent) => {
      event.preventDefault();
      setSubmitting(true);
      await postSubmission();
      setSubmitting(false);
  };

  const postSubmission = async () => {
    const payload = {
        ...formState,
        "g-recaptcha-response": recaptchaToken
    };

    try {
      const result = await axios.post(formSparkUrl, payload);
      console.log(result);
      setMessage({
        class: 'bg-green-500',
        text: 'Thanks, someone will be in touch shortly.',
      });
      setFormState(initialFormState);
      recaptchaRef.current.reset();
    }
    catch(error) {
      console.log(error);
      setMessage({
          class: 'bg-red-500',
          text: 'Sorry, there was a problem.',
      });
    }
  };

  const updateFormControl = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { id, value } = event.target;
      const formKey = id;
      const updateFormState = { ...formState };
      updateFormState[formKey] = value;
      setFormState(updateFormState);
  };

  const updateRecaptchaToken = (token: string | null) => {
    setRecaptchaToken(token);
  };


  return (
    <div className='h-full my-12 flex justify-center flex-col'>
        <div className='w-2/3 m-auto p-8 shadow-lg'>
            <h1 className='text-4xl font-bold flex items-center'>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 transform rotate-45 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
            </svg>
                <span>Contact Us</span>
            </h1>
            {message && <div className={`my-4 text-white w-full p-4 ${message.class}`}>
              {message.text}
              </div>}
            <form className='flex flex-col'onSubmit={submitForm}>
              <div className='my-2 flex flex-col'>
                  <label htmlFor="email">Name</label>
                  <input onChange={updateFormControl} type="text" className='border-2 p-2' id="name" value={formState.name}/>
              </div>

              <div className='my-2 flex flex-col'>
                  <label htmlFor="name">Email</label>
                  <input onChange={updateFormControl} type="text" className='border-2 p-2' id="email" value={formState.email}/>
              </div>

              <div className='my-2 flex flex-col'>
                  <label htmlFor="message">Message</label>
                  <textarea id="message" className='border-2 p-2' onChange={updateFormControl} value={formState.message}>

                  </textarea>
              </div>

              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} onChange={updateRecaptchaToken}/>


              <button disabled={submitting} className='my-2 bg-black text-white w-full p-2 hover:bg-white hover:text-black border-1 border-b transition-colors duration-200'>
                  {submitting ? "Submitting ... " : "Submit"}
              </button>
            </form>
        </div>
    </div>
  )
}
