import React from 'react'
import Footer from '../Components/Footer';
import FundCont from '../Components/Fundraising/FundCont';
import DocumentTitle from 'react-document-title';

function Fundraising() {
  return (
    <DocumentTitle title="Tikobane | Fundraising">
      <div>
          <FundCont />
          <Footer />
      </div>
    </DocumentTitle>
  );
}

export default Fundraising;
