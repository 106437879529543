import React from 'react'
import Content from '../Components/Home/Content';
import Hero from '../Components/Home/Hero';
import Footer from '../Components/Footer';
import { render } from '@testing-library/react';
import DocumentTitle from 'react-document-title';
import Slider from '../Components/Slider';

function Home() {
  return (
    <DocumentTitle title="Tikobane | Home">
        <div className="Home">
        <Hero />
        <Content />
        <Footer />
        </div>
    </DocumentTitle> 
  );
}

export default Home;
