import React from 'react'
import Home from './Home/Home'
import Contact from './Contact/Contact'
import About from './About/About'
import Fundraising from './Fundraising/Fundraising'
import {Routes, Route} from "react-router-dom";
import Navbar from './Components/Navbar';
import Donate from './Fundraising/Donate'
import Events from './Fundraising/Events'
import Volunteer from './Fundraising/Volunteer'

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes><Route path="/" element={<Home/>} /></Routes>
      <Routes><Route path="/contact" element={<Contact/>} /></Routes>
      <Routes><Route path="/about" element={<About/>} /></Routes>
      <Routes><Route path="/fundraising" element={<Fundraising/>} /></Routes>
      <Routes><Route path="/donate" element={<Donate/>} /></Routes>
      <Routes><Route path="/events" element={<Events/>} /></Routes>
      <Routes><Route path="/volunteer" element={<Volunteer/>} /></Routes>
    </div>
  );
}

export default App;
