import React from 'react'
import Slider from '../Slider'
import {GiPlantsAndAnimals} from 'react-icons/gi'
import {BiRecycle} from 'react-icons/bi'
import {IoIosMicrophone} from 'react-icons/io'
import {SiHandshake} from 'react-icons/si'
import {FaChild} from 'react-icons/fa'
import {GiSwordClash} from 'react-icons/gi'
import {Link} from "react-router-dom";
import about1 from '../Images/about1.jpg'
import about2 from '../Images/about2.jpg'
import about3 from '../Images/about3.jpg'
import about4 from '../Images/about4.jpg'
import about5 from '../Images/about5.jpg'
import heroPic from '../Images/Aboutpic.jpg'
const images = [
  about1,
  about2,
  about3,
  about4,
  about5
];

const AboutHero = () => {
  return (
    <div>
      <div className='text-center w-[80%] mx-auto hidden lg:block'>
          <h1 className='text-3xl lg:text-4xl font-bold mx-auto tracking-wider mb-[-.2em]'>Tikobane Trust</h1>
          <h2 className='text-[1.2em] text-gray-700'>What we do & how we do it</h2>
        </div>
      <Slider slides={images}/>
      <div className='h-[85vh] lg:hidden max-w-[1240px] flex flex-col justify-evenly items-center text-black text-left mt-[-4%] md:mt-[-3%]'>
        <img src={heroPic} alt="" className='w-[100%] max-w-[650px]'/>
        <div className='text-center col-span-full'>
          <h1 className='text-3xl lg:text-4xl font-bold col-span-full my-3 mb-[-.1em] tracking-wide'>Tikobane Trust</h1>
          <h2 className='text-[1.2em] mx-auto text-gray-700 leading-tight mt-1'>What we do & how we do it</h2>
        </div>
    </div>
    <div className='grid'>
      <h3 className='w-[79%] md:w-[82%] mx-auto mt-5 text-center'>As a Community Interest Organization, our ethos "Problem Solving" through dialogue we seek out Community Partnerships. Skill sharing through Mentorship our resolve to provide expertise and the tools to address the impact of poverty on food security. Developing sustinable micro Businesses. </h3>
      <Link onClick={() => {window.scrollTo({top: 0, behavior: "smooth"})}}className='col-span-full mx-auto md:mt-4' to="/fundraising"><button className='bg-white w-[200px] rounded-md font-medium mt-5 mx-auto py-3 text-black border-black border-2 hover:bg-black hover:text-white hover:border-white col-span-full'>Learn More</button></Link>
    </div>

      <div className='grid w-[78%] md:w-[90%] lg:w-full md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-[1240px] mx-auto mt-14'>
      <div className='text-left mb-8 md:col-span-full md:text-center lg:mb-16'>
        <h4 className='text-4xl mt-4 font-semibold lg:mt-0'>Our Focus</h4>
        <h5 className='py-5 text-xl mx-auto md:w-full md:text-2xl font-medium'>Sustainable Community Projects Empowering People & Animals</h5>
        <p className='w-full md:w-[82%] md:mx-auto'>We are dedicated to a few central causes to achieve our goals as an organization. We believe these are our foundation and so are crucial. We divide our time and efforts so that we can truly invest in each cause. Read on to learn more!</p>
      </div>
        <div className='.row-span-1 .col-span-1 text-center shadow-xl rounded-lg p-6  hover:scale-105 duration-300'>
          <GiPlantsAndAnimals size={(45)} className='mx-auto mb-5'/>
          <h2 className='text-3xl font-semibold mb-5'>Animal Health & Welfare Education</h2>
          <h3 className='mb-5 text-xl font-medium'>Grassroots Clinical & Husbandry Community Engagement</h3>
          <p>Based in Dete we are working in peri-urban & rural areas. We run Medical access Clinics & Educational Events on Animal Husbandry & Welfare. A lot of our work is done through Outreach networks that way we are on the ground identifying pressure points on working and domestic Animals. We believe that the best solutions from Society come from within.</p>
        </div>
        <div className='.row-span-1 .col-span-1 text-center shadow-xl rounded-lg p-6  hover:scale-105 duration-300'>
          <BiRecycle size={(45)} className='mx-auto mb-5'/>
          <h2 className='text-3xl font-semibold mb-5'>Sustainable Community Development</h2>
          <h3 className='mb-5 text-xl font-medium'>Sharing Empowering Actions through Partnerships</h3>
          <p>We are living and working in our communities identifying skill gaps with dialogue & empowering mentorship from partnerships for development. By building a network, we look for mentor expert partners in various fields that will strengthen our resolve to learn & share skills. Our success is driven by the hearts & minds of our Community.</p>
        </div>
        <div className='.row-span-1 .col-span-1 text-center shadow-xl rounded-lg p-6  hover:scale-105 duration-300'>
          <IoIosMicrophone size={(45)} className='mx-auto mb-5'/>
          <h2 className='text-3xl font-semibold mb-5'>Social Responsibility</h2>
          <h3 className='mb-5 text-xl font-medium'>Caring for each other & the Environment around us</h3>
          <p>As a community, we have responsibilities to each other and the environment around us. Through developing an ethical framework, we work to encourage our community to take responsibility and to work towards a culture of care for each other and the society we live in. Want to learn more?</p>
        </div>
        <div className='.row-span-1 .col-span-1 text-center shadow-xl rounded-lg p-6  hover:scale-105 duration-300'>
          <FaChild size={(45)} className='mx-auto mb-5'/>
          <h2 className='text-3xl font-semibold mb-5'>Our Boys & Girls Clubs</h2>
          <h3 className='mb-5 text-xl font-medium'>Life Skills and Mentorship</h3>
          <p className='mb-4'>Confidence building is an essential pillar of our organization. Guided by a mentorship model, our Boys & Girls Clubs aim to open up opportunities & guidance for teaching life skills, providing an environment that is safe to stay on in school.</p>
          <p>We work to inform and encourage children to take a stand for what they believe in. To materialize their dreams. Want to learn more?</p>
        </div>
        <div className='.row-span-1 .col-span-1 text-center shadow-xl rounded-lg p-6  hover:scale-105 duration-300'>
          <SiHandshake size={(45)} className='mx-auto mb-5'/>
          <h2 className='text-3xl font-semibold mb-5'>Youth Training</h2>
          <h3 className='mb-5 text-xl font-medium'>Training Opening Up Options</h3>
          <p>Tikobane Trust has built an environment of support and helpful reasources to make sure that Education and skill training is always a priority. Our success is driven by opening up options providing tools and experts that will provide training and ongoing support of developing skills to support a living. Come and join us.</p>
        </div>
        <div className='.row-span-1 .col-span-1 text-center shadow-xl rounded-lg p-6  hover:scale-105 duration-300'>
          <GiSwordClash size={(45)} className='mx-auto mb-5'/>
          <h2 className='text-3xl font-semibold mb-5'>Human Wildlife Conflict</h2>
          <h3 className='mb-5 text-xl font-medium'>Our Ethos Co-existence</h3>
          <p>Geographically adjacent to Hwange National park, wild animals cross over into the area. This results in crop raiding & livestock injuries. As an organization on the ground dealing with the consequences, mitigating co-existence through grassroots education projects. Providing expertise to protect livelihoods. With non-lethal sustainable solutions, we work closely with the authorities & traditional leadership to avert clashes of confclit while adopting methods that work.  </p>
        </div>
      </div>
    </div>
  )
}

export default AboutHero