import React from 'react'
import heroPic from '../Images/Heropic.jpg'
import Slider from '../Slider'
import hero1 from '../Images/hero1.jpg'
import hero2 from '../Images/hero2.jpg'
import hero3 from '../Images/hero3.jpg'
import hero4 from '../Images/hero4.jpg'
const images = [
    hero1,
    hero2,
    hero3,
    hero4
];

const Hero = () => {
  return (
      <div>
        <div className='text-center w-[80%] mx-auto hidden lg:block'>
          <h1 className='text-3xl lg:text-4xl font-bold mx-auto tracking-wider mb-[-.2em]'>Tikobane Trust</h1>
          <h2 className='italic text-[1.2em] text-gray-700'>"Sharing - Empowering actions that speak louder than words"</h2>
        </div>
      <Slider slides={images}/>
      <div className='h-[85vh] lg:hidden max-w-[1240px] flex flex-col justify-evenly items-center text-black text-left mt-[-4%] md:mt-[-3%]'>
        <img src={heroPic} alt="" className='w-[100%] max-w-[650px]'/>
        <div className='text-center'>
          <h1 className='text-3xl lg:text-4xl font-bold col-span-full my-3 mb-[-.1em] tracking-wide'>Tikobane Trust</h1>
          <h2 className='italic text-[1.2em] w-[87%] mx-auto text-gray-700 leading-tight mt-1'>"Sharing - Empowering actions that speak louder than words"</h2>
        </div>
    </div>
  </div>
  )
}

export default Hero