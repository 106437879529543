import React from 'react'
import Footer from '../Components/Footer';
import AboutHero from '../Components/About/AboutHero'
import DocumentTitle from 'react-document-title';


function About() {
  return (
    <DocumentTitle title="Tikobane | About">
      <div className="About">
          <AboutHero />
          <Footer />
      </div>
    </DocumentTitle>
  );
}

export default About;
