import React from 'react'
import { ContactForm } from '../Components/Contact/ContactForm';
import Footer from '../Components/Footer'
import DocumentTitle from 'react-document-title';

function Contact() {
  return (
    <DocumentTitle title="Tikobane | Contact Us">
      <div>
        <ContactForm />
        <Footer />
      </div>
    </DocumentTitle>
  );
}

export default Contact;
