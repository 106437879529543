import React from 'react'
import Eventpic from '../Images/Eventpic.jpg'

const EventsInfo = () => {
  return (
    <div>
        <div className='md:hidden text-center h-[86.25vh]'>
            <h4 className='text-3xl font-semibold'>Organizing an Event or Outreach</h4>
            <h5 className='px-6 font-medium text-[1.20rem]'>Would you like to host an event or plan an ourteach? We would really appreciate your help!</h5>
            <img src={Eventpic} alt="" className='w-[87.5%] max-w-[500px] mx-auto mt-3'/>
            <p className='w-[90%] mx-auto mt-3'>
                Most of our work is done via events and outreaches so that we are on the ground. Motivating, inspiring, and empowering our community problems requires addressing the challenges. By organizing or hosting an event, you become a crucial part of our movement and that our message has a far reaching and lasting effect.
            </p>
        </div>
      
        <div id="eventHero" className='hidden md:block max-w-[1240px] w-full md:mt-auto h-[86.5vh] mx-auto text-center'>
          <div id="" className='px-4'>
              <h4 className='text-4xl font-bold pt-[8.5rem] mb-8'>Organizing an Event or Outreach</h4>
              <h5 className='font-medium text-2xl max-w-[650px] mx-auto text-darkgray'>Would you like to host an event or plan an ourteach? We would really appreciate your help!</h5>
              <p className='mt-[4em] max-w-[650px] mx-auto text-[1.25rem] font-semibold'> Most of our work is done via events and outreaches so that we are on the ground. Motivating, inspiring, and empowering our community problems requires addressing the challenges. By organizing or hosting an event, you become a crucial part of our movement and that our message has a far reaching and lasting effect.</p>
          </div>
        </div>
    </div>
  )
}

export default EventsInfo