import React from 'react'
import EventsInfo from '../Components/Fundraising/EventsInfo'
import Footer from '../Components/Footer'

const Events = () => {
  return (
    <div>
        <EventsInfo />
        <Footer />
    </div>
  )
}

export default Events